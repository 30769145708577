.product_card {
  width: 270px;
  background-color: var(--boschGray95);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.product_card .product_card__image {
  width: 100%;
}
.product_card .product_card__name {
  font-weight: 600;
}
.product_card .product_card__learnmore {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  justify-content: center;
}
.product_card .product_card__image .image {
  width: 100%;
}

.right_icon {
  font-size: var(--fs-80) !important;
}

.product_card__learnmore i{
  color: #4183c4 !important;
  font-size: 1rem !important;
}

.product_card__learnmore .learnmore__btn {
  background-color: var(--boschBlue50) !important;
  color: var(--boschWhite) !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  margin: 0;
  /* margin-top: 1rem; */
  /* padding: 8px !important; */
  /* width: 100px !important; */
  /* height: 40px !important; */
}

@media (max-width: 575px) {
  .product_card {
    width: 100% !important;
  }
}
