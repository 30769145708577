.rd_input_group {
  display: flex !important;
  flex-direction: row !important;
  flex: 1;
  position: relative;
  height: 3rem !important;
}
.rd_input_group > span {
  font-size: var(--fs-80);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  margin: 0.25rem 0.75rem auto 1rem;
  line-height: 1.5 !important;
}
.rd_input_group .dropdown[class*="dropdown"] {
  background-color: transparent !important;
  padding: 0 2.75rem 0 1rem !important;
  padding-bottom: 0.3125rem !important;
  padding-top: 1.125rem !important;
  width: 100% !important;
  margin: 0 !important;
  border-radius: 0 !important;
  border: none !important;
}
.rd_input_group > .dropdown[class*="dropdown"] > input[class*="search"] {
  /* top: -50%; */
  background-color: var(--boschGray90) !important;
  z-index: 0 !important;
  padding: 0 2.75rem 0 1rem;
  padding-bottom: 0.3125rem;
  padding-top: 1.125rem;
  height: 3.5rem !important;
  left: 0 !important;
  border-radius: 0 !important;
}
.rd_input_group .dropdown[class*="dropdown"] > input[class*="search"]:hover {
  background-color: var(--boschGray75) !important;
  cursor: pointer;
}
.rd_input_group > .dropdown[class*="dropdown"] > div[class*="text"] {
  font-weight: normal !important;
  color: var(--boschBlack) !important;
  pointer-events: none;
  white-space: nowrap;
}
/* .rd_input_group .ui.active.search.dropdown input.search:focus {
    background-color: var(--boschGray65) !important;
  } */
.rd_input_group
  > .dropdown[class*="dropdown"]
  > input[class*="search"]:focus
  + div[class*="text"] {
  color: var(--boschBlack) !important;
}
.rd_input_group .dropdown[class*="dropdown"] > .rd_icon {
  position: absolute;
  right: 0.7rem !important;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  margin: 0 !important;
  display: flex;
  padding-top: 0.25rem;
}
.rd_input_group .dropdown[class*="dropdown"] > .rd_icon > i {
  margin: 0 !important;
  color: var(--boschBlack) !important;
  font-size: var(--fs-60) !important;
  width: 24px !important;
  height: 24px !important;
}
.rd_input_group .dropdown[class*="dropdown"] > div[class*="menu"] {
  box-shadow: none !important;
  border-radius: 0 !important;
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024,
    0 3px 14px 2px #0000001f !important;
  z-index: 9999;
  border: none !important;
  min-width: 100% !important;
  max-width: 100% !important;
}
.rd_input_group
  .dropdown[class*="dropdown"]
  > div[class*="menu"]
  > div[class*="item"] {
  padding: 0.75rem 1rem 0.75rem !important;
  min-height: 3rem !important;
  display: flex;
  align-items: center;
  white-space: normal;
}
.rd_input_group
  .dropdown[class*="dropdown"]
  div[class*="menu"]
  > div[class*="item"]:hover {
  background-color: var(--boschGray90) !important;
}
.rd_input_group
  .dropdown[class*="dropdown"]
  > div[class*="menu"]
  > div[class*="item"]
  > * {
  color: var(--boschBlack) !important;
}
.rd_input_group
  .dropdown[class*="dropdown"]
  div[class*="menu"]
  > div[class*="active"],
.rd_input_group
  .dropdown[class*="dropdown"]
  div[class*="menu"]
  > div[class*="active"]:hover {
  background-color: var(--boschBlue50) !important;
}
.rd_input_group
  .dropdown[class*="dropdown"]
  div[class*="menu"]
  > div[class*="active"]
  > * {
  color: var(--boschWhite) !important;
  font-weight: normal !important;
}

.header > *:first-child {
  padding: 0.75rem 1rem !important;
  font-size: 1rem !important;
  height: 3rem !important;
}
.error {
  width: 100%;
  padding: 0.25rem 1rem;
  display: flex;
  color: var(--boschRed50) !important;
}
.error > i {
  font-size: 1.5rem !important;
}
