/*Main container*/
.landing_page_container {
  width: 80% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}

.landing_page_container .page_main__header_title {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.landing_page_container .page_main__header_title .header_title {
  display: flex;
  justify-content: flex-end;
}

.landing_page_container .page_main__header_title .header_title p {
  font-size: 15px;
  font-weight: bold;
}

.landing_page_container .page_main__header_title .header_name {
  display: flex;
  justify-content: flex-start;
}

.landing_page_container .page_main__header_title .header_name p {
  font-size: 25px;
  font-weight: bolder;
}

.main_content {
  display: flex;
  flex-direction: row;
  gap: 25px;
  flex-wrap: wrap;
  align-items: center;
}

/*item*/
.main_content .item {
  background-color: #e0e2e5;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  padding-bottom: 20px;
}

.main_content .item .item-image {
  width: 100%;
  height: 487px;
}
.main_content .item .item-image.first {
  background-image: url('../../../assets/Homepage.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.main_content .item .item-image.second {
  background-image: url('../../../assets/TR_Bild_Bosch_Visual_Connect_A.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.page-main--footer {
  width: 100%;
  margin-top: auto;
}

@media only screen and (max-width: 768px) {
  .main_content {
    flex-wrap: wrap;
    justify-content: center;
  }

  .main_content .item {
    width: 100%;
  }

}


@media only screen and (max-width: 950px) and (min-height: 1024px){
  .landing_page_container{
    height: 100% !important;
  }
}
