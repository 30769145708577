@font-face {
  font-family: 'Bosch-Icon';
  src: url('./assets/fonts/Bosch-Icon.eot');
  src: url('./assets/fonts/Bosch-Icon.eot') format('embedded-opentype'),
    url('./assets/fonts/Bosch-Icon.ttf') format('truetype'),
    url('./assets/fonts/Bosch-Icon.woff') format('woff'),
    url('./assets/fonts/Bosch-Icon.woff2') format('woff2'),
    url('./assets/fonts/Bosch-Icon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
.Boschicon {
  font-family: Bosch-Icon !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

button.ui.button {
  border-radius: 0;
}

button.ui.button.primary-btn {
  background: #005691;
  color: #fff;
}

/* Override font family to BOSCH
body{
  font-family:Bosch Sans,'Helvetica Neue',Helvetica,Arial,sans-serif !important;
} */
/* Override font size*/

/*Text 5XL*/
h1 {
  font-size: 4rem !important;
}

/*Text 4XL*/
h2 {
  font-size: 3rem !important;
}

/*Text 3XL*/
h3 {
  font-size: 2.5rem !important;
}

/*Text 2XL*/
h4 {
  font-size: 2rem !important;
}

/*Text XL*/
h5 {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
}

body,
html {
  font-size: 16px !important;
}

/* body div {
  width: 100%;
} */
/* 
div.app-body {
  display: grid;
  grid-template-columns: minmax(16px, 1fr) minmax(750px, 1170px) minmax(16px, 1fr);
} */
div.app-body-container {
  padding: 0rem 1.875rem;
}

/* grid responsive */
.grid-responsive-width {
  width: calc(100% - 2rem);
  max-width: 80%;
  margin: 0 auto !important;
}

.grid-responsive-width > .ui.grid:first-child {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.container-pcs{
  min-height: 100% !important;
}

@media only screen and (min-width: 1152px) {
  .grid-responsive-width {
    width: 100% !important;
    margin: 0 auto !important;
  }
}

/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  .grid-responsive-width {
    max-width: 100% !important;
  }
}

/* Styles for tablet devices */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .grid-responsive-width {
    max-width: 100% !important;
  }
}

/* unordered list with green check as bullets */
ul.green-check-as-bullets-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 24px;
}

ul.green-check-as-bullets-ul li {
  display: flex;
}

ul.green-check-as-bullets-ul li::before {
  content: '';
  display: inline-block;
  height: 1.4rem;
  width: 1.4rem;
  margin-right: 0.5rem;
  background-image: url('/src/assets/svg/checkmark-frame.svg');
  vertical-align: sub;
  background-repeat: no-repeat;
  flex: 1;
}

ul.green-check-as-bullets-ul li > p {
  flex: 10;
}

/*primary button*/
button.ui.button.bwc-btn--primary {
  background: #007bc0;
  color: #ffffff;
  width: 7rem;
  font-weight: 400;
}

button.ui.button.bwc-btn--primary:active {
  background: #004975;
}

button.ui.button.bwc-btn--primary:hover {
  background: #00629a;
}

button.ui.button.bwc-btn--secondary {
  background: #fff;
  border: 1px solid #007bc0;
  color: #007bc0;
  width: 7rem;
  font-weight: 400;
}

button.ui.button.bwc-btn--secondary:active,
button.ui.button.bwc-btn--secondary:focus {
  background: #9dc9ff;
  border: 1px solid #004975;
  color: #004975;
}

button.ui.button.bwc-btn--secondary:hover {
  background: #d1e4ff;
  border: 1px solid #00629a;
  color: #00629a;
}

.app-message-container {
  overflow: hidden;
  height: calc(100vh - 12rem);
  padding: 0;
}

/*Container*/

.container-landingpage {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu{
  max-height: 300px !important;
  overflow-y: auto !important;
}
