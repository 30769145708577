.notification_bar {
  min-height: 4rem;
  display: flex;
  flex-direction: row;
  position: sticky;
  align-items: center;
  bottom: 0;
  max-width: 80%;
  margin: 0 auto !important;
}

.notification_bar.warning {
  background-color: var(--boschYellow90);
  color: var(--boschBlack);
}
.notification_bar.success {
  background-color: var(--boschGreen90);
  color: var(--boschBlack);
}

.notification_bar .notification_message {
  min-height: 2rem;
  flex-grow: 4;
}
.notification_bar .icon_noti > i {
  font-size: var(--text-2xl);
  margin: 1rem 0.5rem 1rem 1rem;
}
.notification_bar .icon_close > i {
  font-size: var(--text-2xl);
  margin: 1rem 0.5rem 1rem 1rem;
  cursor: pointer;
}

.notification_bar .icon_close {
  size: var(--text-4xl);
  margin: 0.5rem, 0.5rem 0.5rem, 1rem
}

.notification_bar .notification_message {
  font-size: var(--text-m);
  margin: 1.25rem 1rem 1.25rem 0.75rem;
  display: flex;
  align-items: center;
}
