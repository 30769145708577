.publicDashboard {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
}

.publicDashboard .header_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.publicDashboard .header_title .translate_modal_btn{
  background-color: var(--boschWhite);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  font-weight: 500;
}

.publicDashboard .header_title .translate_modal_btn.disable{
  color: var(--boschGreen50) !important;
}

.publicDashboard .header_title p {
  font-size: 15px;
  font-weight: bold;
  margin-left: 0px;
}
.publicDashboard .header_title a {
  color: green;
}

.publicDashboard .session_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--boschGray40);
  color: white;
  padding: 0.5rem 0;

}
.publicDashboard .session_title p{
  font-size: var(--fs-normal);
  padding-left: 1rem;
  font-weight: 600;
}

.publicDashboard .chatting_area {
  height: 100%;
  border: 1px black solid;
  margin-bottom: 1rem;
}

.publicDashboard .chatting {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  .publicDashboard {
    width: calc(100% - 2rem);
  }
}

/* Styles for tablet devices */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .publicDashboard {
    width: calc(100% - 2rem);
  }
}
