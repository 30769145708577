.header--icon {
  display: flex;
  flex-direction: row-reverse;
  background-color: white;
}

.header--icon .header--icon--language {
  padding: 0px;
  margin: 0px;
  background-color: white;
}

.header--icon .header--icon--language .divider.text {
  display: none !important;
}

.header--icon .header--icon--account {
  padding: 0px;
  margin: 0px;
  background-color: white;
}

.header--icon .header--icon--language .icon {
  font-size: 22px;
}

.header--icon .header--icon--account .icon {
  font-size: 22px;
}

.header--icon .header--icon--language:hover {
  background-color: white;
}

.header--icon .header--icon--account:hover {
  background-color: white;
}

.header--icon .header--icon--language .header--icon--language--key {
  position: absolute;
  font-size: 13px;
  display: block;
  left: 23% !important;
  top: 14% !important;
}

.ui.floating.dropdown .menu {
  left: -145px !important;
}


/* Styles for mobile devices */
@media only screen and (max-width: 500px) {
  .header--icon .header--icon--language .icon {
    font-size: 15px;
  }
  
  .header--icon .header--icon--account .icon {
    font-size: 15px;
  }

  .header--icon .header--icon--language .header--icon--language--key {
    position: absolute;
    font-size: 8px;
    display: block;
    left: 23% !important;
    top: 18% !important;
  }

}
