.rd_input_group {
  display: flex !important;
  flex-direction: row !important;
  flex: 1;
  position: relative;
  height: 3rem !important;
}
.rd_input_group > span {
  font-size: var(--fs-100);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  margin: 0.25rem 0.75rem auto 1rem;
  line-height: 1.5 !important;
  pointer-events: none;
}

.sc_user_input {
  height: 3rem;
  margin: 1rem;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: lightgray !important;
  border-bottom: 0.0625rem solid black !important;
  transition: background-color 0.2s ease, box-shadow 0.2s ease !important;
}

.sc_user_input.active {
  box-shadow: none;
  background: #d1e4ff !important;
  box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2) !important;
}

.sc_user_input__text {
  width: 100% !important;
  resize: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: black !important;
  -webkit-font-smoothing: antialiased;
  bottom: 0;
  /* overflow-x: hidden;
    overflow-y: auto; */
  cursor: text;
}

.sc_user_input__text > input {
  background: transparent !important;
  border: none !important;
  padding-bottom: 0px !important;
}

.sc_user_input__text:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: black;
  outline: none;
}

.userInput__button {
  background: none !important;
  margin: 0 !important;
  width: 40px !important;
}
