li span.date-header {
  width: fit-content;
  width: -moz-fit-content;
  margin: 0 auto;
  display: flex;
}

.public-message-list-joined--note {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.public-message-list-joined--note .join--title p {
  line-height: 1.43;
  font-size: 0.65rem;
}
