/*Start information-modal*/
.wrapper_modal {
  display: flex;
  justify-content: center !important;
  background-color: #71767c40;
  -webkit-backdrop-filter: blur(0.25rem);
  backdrop-filter: blur(0.25rem);
}

.wrapper_modal > .modal {
  max-width: 40rem !important;
}

.wrapper_modal.warning > .modal {
  border-top: 0.375rem solid var(--boschYellow85);
  border-radius: 0 !important;
}

.wrapper_modal.error > .modal {
  border-top: 0.375rem solid var(--boschRed50);
  border-radius: 0 !important;
}

.wrapper_modal.success > .modal {
  border-top: 0.375rem solid var(--boschGreen50);
  border-radius: 0 !important;
}

.wrapper_modal.info > .modal {
  border-top: 0.375rem solid var(--boschBlue50);
  border-radius: 0 !important;
}

.wrapper_modal > .modal > .header {
  border: 0 !important;
  padding: 0 !important;
  align-items: flex-start;
  display: flex;
  border-bottom: 0.0625rem solid var(--boschGray60) !important;
}
.wrapper_modal > .modal > .header > span.title {
  font-size: var(--text-m) !important;
  font-weight: bold;
  margin: 0.75rem 2rem 0.75rem 0.5rem;
  flex: 1;
}
.wrapper_modal > .modal > .header > i {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem !important;
  margin: 0.75rem 0.5rem 0.75rem 2rem !important;
}
.wrapper_modal > .modal > .header > .close_button_header {
  background-color: transparent !important;
}
.wrapper_modal > .modal > .header > .close_button_header > i {
  margin: 0 !important;
  font-size: 1.5rem !important;
}
.wrapper_modal > .modal > .content {
  padding: 1.5rem 2rem 0 2rem !important;
}
.wrapper_modal > .modal > .content > .ui.grid {
  margin: 0;
  font-size: var(--text-m) !important;
}
.wrapper_modal > .modal > .content > .ui.grid > .row {
  padding: 0 !important;
  margin-bottom: 1rem !important;
}
.content_header {
  font-size: var(--text-xl) !important;
  font-weight: bold !important;
}
.wrapper_modal > .modal > .content > .ui.grid ul {
  margin: 0 !important;
  padding-left: 1rem !important;
}
.wrapper_modal > .modal > .actions {
  border: 0 !important;
  padding: 1.5rem 2rem 2rem 2rem !important;
  background: transparent !important;
  gap: 1rem !important;
  display: flex !important;
  justify-content: flex-end;
}

.wrapper_modal > .modal > .actions > button {
  min-width: 125px;
  font-weight: normal;
}

@media (max-width: 767px) {
  .wrapper_modal > .modal {
    width: 100% !important;
    overflow: hidden;
    display: grid !important;
    grid-template-rows: repeat(3, auto) 1fr;
    grid-template-areas:
      "remark"
      "header"
      "divider"
      "content";
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
  }
  .wrapper_modal > .modal > .header {
    font-size: 1rem !important;
  }

  .wrapper_modal > .modal > .content > .ui.grid p {
    font-size: 0.813rem !important;
  }

  .wrapper_modal > .modal > .actions {
    display: flex !important;
    flex-direction: column;
    gap: 1rem !important;
  }
  .wrapper_modal > .modal > .actions > button {
    font-size: 0.875rem !important;
    flex: 1;
  }
}
/*End information-modal*/
