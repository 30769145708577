.deleteModal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}

.deleteModal .colorBar {
  width: 100%;
  height: 20px;
  background-color: green;
}

.deleteModal .error-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 25px 0px 25px;
}

.deleteModal .error-area .btn--option {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 5px;
}
.deleteModal .error-area .btn--option .button {
  border-radius: 0% !important;
  font-weight: 300 !important;
}

.deleteModal .error--title {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
}

.deleteModal .error--title p {
  font-size: 13px;
  font-weight: 600;
}
.deleteModal .error--content p {
  font-size: 13px;
  font-weight: 500;
}

.deleteModal .error--title .Boschicon {
  font-size: 30px;
  font-weight: 500;
}
