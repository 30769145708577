.message-attachment {
  display: flex;
  flex-direction: column;
  background-color: #f0f1f2;
}
.message-attachment .message-attachment--infor {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  margin: 5px;
  align-items: center;
}
.message-attachment
  .message-attachment--infor
  .message-attachment--infor--icon {
}
.message-attachment
  .message-attachment--infor
  .message-attachment--infor--name {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.message-attachment
  .message-attachment--infor
  .message-attachment--infor--name
  p {
  margin: 0;
}
.message-attachment
  .message-attachment--infor
  .message-attachment--infor--icon
  .Boschicon {
  font-size: 25px !important;
}

.message-attachment--infor--name .file-name {
  font-size: 15px;
  font-weight: 600;
}
.message-attachment--infor--name .file-size {
  font-size: 10px;
  font-weight: 500;
  color: #616672;
}
.message-attachment .message-attachment--image {
  display: flex;
  justify-content: center;
}

.message-attachment .message-attachment--image img{
  width: 200px !important;
}
