.sc_message_list {
  height: 100%;
  padding-top: 1rem;
  overflow: auto;
}

.sc_message_today {
  text-align: -webkit-center;
  text-align: -moz-center;
}

.sc_message_list p {
  background-color: var(--boschGray20);
  text-align: center;
  font-size: var(--fs-100);
  color: var(--boschWhite);
  width: fit-content;
  padding: 3px 1rem;
  border-radius: 5px;
}

.sc_message_list a {
  text-align: center;
  font-size: var(--fs-100);
  color: var(--boschBlue70);
  width: fit-content;
  padding: 3px 1rem;
  text-decoration: underline;
  cursor: pointer;
}

.messages.sc_message {
  width: 100% !important;
  padding: 0 0.5rem 5px 1rem !important;
  display: flex;
  flex-direction: column;
  margin-top: 5px !important;
}

.messages .sc_message--content {
  width: 100%;
  display: flex;
  margin: 3px;
}

.messages .sc_message--content.received {
  align-self: flex-start;
}

.messages .sc_message--content.sent {
  justify-content: flex-end;
  align-self: flex-end;
}

.messages .sc_message--content.sent .sc_message--avatar {
  display: none;
}

.sc_message--avatar {
  background-image: url(https://d13yacurqjgara.cloudfront.net/assets/avatar-default-aa2eab7684294781f93bc99ad394a0eb3249c5768c21390163c9f55ea8ef83a4.gif);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 15px;
}

.messages .sc_message--text {
  padding: 0.5rem;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
}

.messages .sc_message--content.sent .sc_message--text {
  color: white;
  background-color: var(--boschBlue50);
  word-wrap: break-word;
  max-width: 15.375rem;
}

.messages .sc_message--content.received .sc_message--text {
  color: #263238;
  background-color: #eff1f2;
  margin-right: 40px;
  max-width: 15.375rem;
}

.sc_message--emoji {
  font-size: 40px;
}

.sc_message--file {
  background: white;
  border: solid 1px #cccdd1;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  text-decoration: none;
}

.sc_message--file p {
  margin: 0px 0px 0px 10px;
  color: rgba(86, 88, 103, 0.6);
}

.sc_message--file .sc-user-input--file-icon:hover path {
  fill: rgba(86, 88, 103, 0.3);
}

.sc-author-name {
  display: flex;
}

.sc-author-name.sent {
  justify-content: flex-end;
}

.sc-author-name .sc_message--text {
  color: black;
  font-weight: bolder;
  font-size: 12px;
  padding: 0px;
}

.sc-timestamp {
  display: flex;
  height: 20px;
}

.sc-timestamp.sent {
  justify-content: flex-end;
}

.sc-timestamp .sc_message--text {
  padding: 0px;
  font-size: 11px;
  margin-bottom: 1rem;
}

.ch-timestamp {
  padding-right: 0px !important;
}

.message_time {
  margin-right: auto;
}

.sc_message > div > div[data-testid='chat-bubble'] + div {
  margin-left: unset !important;
  margin-right: unset !important;
}

.joined_session {
  align-self: center;
}

.message_bubble {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.icon_sender i {
  font-size: 1.5rem !important;
}

.sc-hiDMwi.fMBhyc {
  margin-left: 0 !important;
}

.busy_icon i,
.offline_icon i,
.onhold_icon i,
.online_icon i {
  position: absolute !important;
  top: 12px !important;
  right: -3px !important;
  font-size: 0.8rem !important;
  background-color: var(--boschWhite);
  border-radius: 100%;
  height: auto;
  width: auto;
}

.busy_icon i {
  color: var(--boschRed40);
}
.onhold_icon i {
  color: orange;
}
.online_icon i {
  color: var(--boschGreen50);
}

.avatarIcon {
  position: relative;
}

@media (max-width: 450px) {
  .sc_message {
    width: 80%;
  }
}

.progress-bar {
  margin: 1px !important;
}

.ch-attachment-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ch-attachment-icon svg {
  position: unset !important;
}
