/* Header */
.ui.grid div.row.shopping-cart-header-content {
  flex-direction: column;
}

div.row.shopping-cart-header-content h1 {
  margin: 0;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > .ui.grid.container.checkout-step-1-your-cart {
  margin: 0;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > .ui.grid.container.checkout-step-1-your-cart
  > div.ui.divider {
  margin: 0 !important;
  width: 100%;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > .ui.grid.container.checkout-step-1-your-cart
  > div.row#total-price {
  padding: 0.5rem 0;
  justify-content: space-between;
  font-size: 1rem;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > .ui.grid.container.checkout-step-1-your-cart
  > div.row#total-price
  > span#price {
  font-weight: 700;
  color: #005691;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > .ui.grid.container.checkout-step-1-your-cart
  > div.row#total-price-details-container {
  padding: 0.5rem 0;
  justify-content: flex-end;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > .ui.grid.container.checkout-step-1-your-cart
  > div.row#total-price-details-container
  > .ui.grid#total-price-details {
  margin: 0;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > .ui.grid.container.checkout-step-1-your-cart
  > div.row#total-price-details-container
  > .ui.grid#total-price-details
  > div.row#total-price-without-vat,
.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > .ui.grid.container.checkout-step-1-your-cart
  > div.row#total-price-details-container
  > .ui.grid#total-price-details
  > div.row#vat {
  text-align: end;
  justify-content: flex-end;
  font-size: 1rem;
  padding: 0.2rem 0;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > .ui.grid.container.checkout-step-1-your-cart
  > div.row#total-price-details-container
  > .ui.grid#total-price-details
  > div.row#total-price-without-vat
  > div.column#total-price-without-vat-price
  .ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > .ui.grid.container.checkout-step-1-your-cart
  > div.row#total-price-details-container
  > .ui.grid#total-price-details
  > div.row#vat
  > div.column#vat-price {
  font-weight: 700;
  color: #005691;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container {
  padding: 0.5rem;
  border: 1px solid lightgray;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > table
  > tbody
  > tr
  > td.checkout-step-1-your-cart-quantity {
  text-align: center;
  vertical-align: baseline;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > table
  > tbody
  > tr
  > td.checkout-step-1-your-cart-total-price {
  display: flex;
  flex-direction: column;
  text-align: end;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > table
  > tbody
  > tr
  > td.checkout-step-1-your-cart-total-price
  > span {
  font-size: 0.6rem;
}

.ui.grid.container
  > div.row
  > div.column
  > div.check-out-step-1-your-cart-container
  > table
  > tbody
  > tr
  > td.checkout-step-1-your-cart-total-price
  > span.checkout-step-1-your-cart-total-price-value {
  font-weight: 700;
  color: #005691;
  font-size: 1.4rem;
}

/*form*/
.ui.grid.container > div.row > div.column > .ui.form {
  padding-right: 0.5rem;
}

.ui.grid.container > div.row > div.column > .ui.form > div.field {
  width: 100% !important;
}

/* continue buttons */
.ui.grid.container > div.row.space-between-buttons {
  justify-content: space-between;
}

.ui.grid.container > div.row.flex-end-buttons {
  justify-content: flex-end;
}

.ui.grid.container > div.row.checkout-buttons > a {
  align-self: center;
}

.ui.grid.container
  > div.row.checkout-buttons
  > button.ui.button.checkout-back-button {
  color: #005691 !important;
  box-shadow: none;
  padding: 0;
  font-size: 1.5rem;
}

.ui.grid.container
  > div.row.checkout-buttons
  > button.ui.button.checkout-back-button
  > i.icon {
  margin: 0 0.25rem 0 0 !important;
  vertical-align: sub;
}

.ui.grid.container
  > div.row.checkout-buttons
  > button.ui.button.checkout-back-button
  > i.icon
  > svg {
  fill: #005691;
}

.ui.grid.container
  > div.row.checkout-buttons
  > button.ui.button.checkout-back-button:active {
  background: transparent !important;
}

/* total price */
.ui.grid.shopping-cart-total-price {
  margin: 0;
}

.ui.grid.shopping-cart-total-price > div.ui.divider {
  margin: 0 !important;
}

.ui.grid.shopping-cart-total-price > div.row#total-price {
  display: flex;
  justify-content: flex-end;
  font-size: 1.2rem;
}

.ui.grid.shopping-cart-total-price > div.row#total-price > span#price {
  padding-left: 8rem;
  color: #005691;
  font-weight: 700;
}

.ui.grid.shopping-cart-total-price > div.row#total-price > span#price {
  padding-left: 8rem;
  color: #005691;
  font-weight: 700;
}

.ui.grid.shopping-cart-total-price > div.row > .ui.grid#total-price-details {
  margin: 0;
  width: 100%;
  justify-content: flex-end;
  font-size: 1rem;
}

.ui.grid.shopping-cart-total-price
  > div.row
  > .ui.grid#total-price-details
  > div.row#total-price-without-vat
  > div.column,
.ui.grid.shopping-cart-total-price
  > div.row
  > .ui.grid#total-price-details
  > div.row#vat
  > div.column {
  text-align: end;
}

.ui.grid.shopping-cart-total-price
  > div.row
  > .ui.grid#total-price-details
  > div.row#total-price-without-vat
  > div.column#total-price-without-vat-price,
.ui.grid.shopping-cart-total-price
  > div.row
  > .ui.grid#total-price-details
  > div.row#vat
  > div.column#vat-price {
  color: #005691;
  font-weight: 700;
}

/*form*/
.ui.grid.container > div.row > form {
  width: 100%;
}

/*payment */
.ui.grid.container > div.row > form > .my-billing-payment-radio-group {
  width: 100%;
  display: flex;
}

.ui.grid.container > div.row > form > .my-billing-payment-radio-group > div {
  margin-right: 8rem;
}

.ui.grid.container
  > div.row
  > form
  > .my-billing-payment-radio-group
  > div:last-child {
  margin-right: 0;
}

/*address details*/
.ui.grid.container > div.row > form > .checkout-address-form-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.ui.grid.container
  > div.row
  > form
  > .checkout-address-form-header
  > button.ui.button {
  background: #fff !important;
}

.ui.grid.container > div.row > form > .checkout-address-info {
  flex-direction: column;
  margin: 0;
}

.ui.grid.container > div.row > form > .checkout-address-info > div.field {
  margin-bottom: 0.5rem;
  padding-left: 1rem;
}

.grey-bg {
  background: grey;
}

.white-bg {
  background: white;
}

/*progress bar*/
.line {
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  width: 100%;
  border-top: 1px solid rgb(221, 219, 219);
  position: absolute;
  left: 0;
  margin-top: 170px;
}

.text1 {
  font-size: '20px';
}

/* progress bar */
.ui.grid.container > div.row.checkout-progress-bar {
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui.grid.container > div.row.checkout-progress-bar > i.inverted.circular.icon {
  background-color: #005691 !important;
}

.ui.grid.container > div.row.checkout-progress-bar > i.icon {
  padding: 0.6rem !important;
}

.ui.grid.container > div.row.checkout-progress-bar > i.icon > svg {
  /* height: 100%;
  width: 100%; */
  height: 30px;
  width: 30px;
  fill: #fff;
}

.ui.grid.container
  > div.row.checkout-progress-bar
  > i.circular.icon
  > div.ui.label {
  position: relative;
  margin-top: 30%;
  background-color: #fff;
  font-weight: 400;
}

/*progress bar*/
.ui.grid.container > div.row.checkout-progress-bar > div.ui.progress {
  /* margin: 1.5em -0.08rem 0 -0.5rem; */
  margin: 0;
  z-index: -1;
  width: 90%;
  height: 1px !important;
  background-color: #e0e2e5;
}

/*complete percent bar*/
.ui.grid.container > div.row.checkout-progress-bar .ui.progress.success .bar {
  background-color: #00884a !important;
}

.ui.grid.container > div.row.checkout-progress-bar > div.ui.progress > div.bar {
  background-color: #005691;
  height: 2px !important;
  min-width: 0;
  top: -0.5px;
}

/* test dropdown */
.ui.grid.container
  > div.row
  > div.ui.dropdown.checkout-dropdown
  > div.visible.menu.transition {
  width: 200%;
}

.ui.grid.container
  > div.row
  > div.ui.dropdown.checkout-dropdown
  > div.visible.menu.transition
  > div.item {
  font-size: 0.5rem;
}

/*express checkout*/
.ui.grid.container > div.row.checkout-express-checkout {
  flex-direction: column;
}

.ui.grid.container > div.row.checkout-express-checkout > button.ui.button {
  padding: 0 1rem;
  background: #000;
  width: 15rem;
}

/*order summary*/
.ui.grid.container > div.row > div.checkout-order-summary-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ui.grid.container
  > div.row
  > div.checkout-order-summary-header
  > button.ui.button {
  box-shadow: none;
  padding: 0;
}

.ui.grid.container
  > div.row
  > div.checkout-order-summary-header
  > button.ui.button:active {
  background: transparent !important;
}

.ui.grid.container
  > div.row
  > div.checkout-order-summary-header
  > button.ui.button
  > i.icon {
  margin: 0 0.25rem 0 0 !important;
  vertical-align: sub;
}

.ui.grid.container
  > div.row
  > div.checkout-order-summary-header
  > div.checkout-dropdown {
  display: flex;
  justify-content: space-between;
  padding: 0.78571429em 0.3rem 0.78571429em;
}

.ui.grid.container.checkout-order-summary {
  margin: 0;
}

/*payment details*/
.ui.grid.checkout-payment-details {
  width: 100%;
  margin: 0;
}

.ui.grid.checkout-payment-details > div.row.checkout-payment-details-header {
  display: flex;
  justify-content: space-between;
}

.ui.grid.checkout-payment-details
  > div.row.checkout-payment-details-header
  > div.checkout-payment-details-header-buttons {
  display: flex;
}

.ui.grid.checkout-payment-details
  > div.row.checkout-payment-details-header
  > div.checkout-payment-details-header-buttons
  > button.ui.button
  > i.icon {
  margin: 0 0.25rem 0 0 !important;
  vertical-align: sub;
}

.ui.grid.checkout-payment-details
  > div.two.column.row
  > div.column:first-child {
  padding-right: 0.5rem;
}

.ui.grid.checkout-payment-details > div.two.column.row > div.column:last-child {
  padding-left: 0.5rem;
}

.ui.grid.checkout-payment-details
  > div.two.column.row
  > div.column
  > div.inline.fields {
  margin: 0;
}

.ui.grid.checkout-payment-details > div.two.column.row > div.column > label {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}

.ui.grid.checkout-payment-details
  > div.two.column.row
  > div.column
  > div.inline.fields
  > div.required.field
  > div.ui.input {
  width: 4rem;
}

.ui.grid.checkout-payment-details
  > div.two.column.row
  > div.column
  > div.inline.fields
  > div.required.field
  > div.ui.input
  > input {
  text-align: center;
  padding: 0.67857143em 0.5em;
}

.ui.grid.checkout-payment-details
  > div.two.column.row
  > div.column
  > div.inline.fields
  > div.required.field:last-child {
  padding: 0 0 0 1em;
}

div.column.checkout-payment-details-security-code
  div.required.field
  > div.checkout-payment-details-security-code-content {
  display: flex;
}

div.column.checkout-payment-details-security-code
  div.required.field
  > div.checkout-payment-details-security-code-content
  > div.ui.input {
  width: 20%;
}

div.column.checkout-payment-details-security-code
  div.required.field
  > div.checkout-payment-details-security-code-content
  > i {
  align-self: center;
  margin: 0 1rem;
  height: 100%;
  font-size: 2rem;
}

div.column.checkout-payment-details-security-code
  div.required.field
  > div.checkout-payment-details-security-code-content
  > p {
  font-size: 0.8rem;
}

.ui.grid > div.row.checkout-payment-details-buttons {
  display: flex;
  justify-content: space-between;
}

/* background modification for step 3*/
.ui.grid.checkout-step-3 {
  padding: 0 1rem;
  border-radius: 0.5rem;
  background: #e0e1e2;
}

.ui.grid.checkout-step-3 > div.row {
  background: white;
  margin: 1rem 0 0 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.ui.grid.checkout-step-3 > div.row:last-child {
  margin: 1rem 0 1rem 0;
}

.ui.grid.checkout-step-4 div.row div.divider {
  width: 100%;
}

.ui.grid.checkout-step-4 div.row p {
  font-style: italic;
  font-weight: normal;
}

.ui.grid.checkout-step-4 div.row h4 {
  font-weight: 400;
  font-style: italic;
  font-size: 28px !important;
}

.ui.grid.checkout-step-4 div.row h3#summary-header {
  font-size: 34px !important;
  font-weight: 400;
}

/* checkout step 2 company info*/
div.checkout-step-2-company-info {
  background: #e0e2e5;
  padding: 2rem;
  width: 100%;
}

.checkout-step-2-company-info p {
  margin-bottom: 5px;
}

div.checkout-step-2-company-info h4 {
  font-weight: 400;
}

div.checkout-step-2-company-info h5 {
  margin: 0;
}

div.checkout-step-2-company-info h5#checkout-step-2-company-info-company-name {
  font-weight: 700 !important;
  margin-bottom: 10px;
}

div.checkout-step-2-checkbox div.ui.checkbox label:before {
  width: 27px;
  height: 27px;
}

div.checkout-step-2-checkbox div.ui.checkbox label:after {
  font-size: 27px;
  top: 4px;
  width: 27px;
  height: 27px;
}

div.checkout-step-2-checkbox div.ui.checkbox label h5 {
  margin-left: 1rem;
}

div.checkout-step-2-checkbox div.ui.checkbox label {
  padding-top: 5px;
}

div.checkout-step-2-checkbox div.ui.checkbox span {
  margin-left: 10px;
}

.checkout-gift-code-form-container {
  display: flex;
  flex-direction: row;
}

.checkout-gift-code-form-container .apply-button {
  background: #005691;
  color: #fff;
}

.gift-card-notification {
  padding: 0 30px;
}

#component-container {
  width: 100%;
}

.w-100 {
  width: 100;
}

.agree-term-wrapper {
  display: flex !important;
  align-items: baseline;
  justify-content: baseline;
}

.agree-term-wrapper .checkbox-wrapper {
  width: 45px !important;
}

@media (max-width: 576px) {
  .checkout-progress-bar > i > .label {
    display: none;
  }

  .checkout-progress-bar .progress {
    width: 10% !important;
  }

  div.checkout-step-2-checkbox div.ui.checkbox label {
    padding-top: 0px;
  }

  .agree-term-wrapper .checkbox-wrapper .checkbox {
    width: 45px !important;
  }

  .agree-term-wrapper .checkbox-wrapper .checkbox label::before {
    top: 10px !important;
  }

  .agree-term-wrapper .checkbox-wrapper .checkbox label::after {
    top: 15px !important;
  }
}

.ui.table tr td {
  border-top: 0.75rem solid white !important;
  padding: 0.8125em 0.8125em;
}

.ui.grid.container > div.row.my-ticket-headline {
  font-size: 34px !important;
  padding: 0;
  margin-top: 2rem;
}

.ui.grid.container
  > div.row.my-tickets-navigation-button
  > button.ui.button.back-button {
  color: #005691 !important;
  box-shadow: none;
  padding: 0;
  font-size: 1.5rem;
}

.ui.grid.container
  > div.row.my-tickets-navigation-button
  > button.ui.button.back-button
  > i.icon {
  margin: 0 0.25rem 0 0 !important;
  vertical-align: sub;
}

.ui.grid.container
  > div.row.my-tickets-navigation-button
  > button.ui.button.back-button
  > i.icon
  > svg {
  fill: #005691;
}

.ui.grid.container
  > div.row.my-tickets-navigation-button
  > button.ui.button.back-button:active {
  background: transparent !important;
}

div.row.my-requests-supporting-hours h5 {
  margin: 0.2rem 0;
}

.field-title {
  display: none !important;
}

div.my-tickets-sub-headline-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div.my-tickets-sub-headline-field > div {
  display: flex;
  align-items: center;
}

div.my-tickets-sub-headline-field .ui.button {
  border-radius: 0;
  margin: 0;
}

div.my-tickets-sub-headline-field .ui.button div {
  line-height: 18px;
}

.new-request-button {
  white-space: nowrap;
}

@media (max-width: 576px) {
  .ui.table tr {
    margin: 0px !important;
    margin-bottom: 10px !important;
  }

  .ui.table tr td {
    border-top: none !important;
  }

  .ui.table:not(.unstackable) > thead {
    display: none !important;
  }

  .field-title {
    font-weight: 600;
    min-width: 125px !important;
    display: inline-block !important;
  }

  /* .field-value{
    
  } */
}

@media (min-width: 768px) {
  .text-center-min-768 {
    text-align: center !important;
  }
}

/*  */
/* .button {
  height: 60px;
  width: 60px;
  margin-top: 16px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 60px;

  background: #ffffff;
  background-image: linear-gradient(-180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%);
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.24);
  cursor: pointer;
} */

.button svg {
  fill: #000;
  opacity: 0.8;
}

.button--closed svg {
  position: relative;
  left: 3px;
  fill: #0043f7;
}

.button:hover svg {
  opacity: 1;
}

.txt-voucher-inputValue {
  min-width: 300px;
}

.text-invalidVoucher {
  padding-top: 0px !important;
  color: red;
}

.message-channel-modal {
  z-index: 900 !important;
}

.message-container-description {
  margin: unset !important;
}

/*Main container*/

.grid-responsive-width.page-main {
  background-color: #f2f2f2;
  height: 100%;
}

.grid-responsive-width.page-main .page-main--header-title {
  background-color: #3b3838;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.grid-responsive-width.page-main .page-main--content {
  background-color: #f2f2f2;
  display: grid;
  height: 100%;
}

.grid-responsive-width.page-main .page-main--header-title .header-title {
  display: flex;
}
.grid-responsive-width.page-main .page-main--header-title .header-btn {
  display: flex;
}
.grid-responsive-width.page-main .page-main--header-title .header-btn button {
  width: 199px;
  margin: 5px 5px 5px 0;
  height: 50px;
}

.grid-responsive-width.page-main .page-main--header-title p {
  color: #fff;
  display: flex;
  align-items: center;
  margin-left: 40px;
  font-size: 31px;
  font-weight: bolder;
}

.grid-responsive-width.page-main .page-main--body-container {
  margin-left: 40px;
  margin-top: 20px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 72vh;
}

.grid-responsive-width.page-main
  .page-main--body-container
  .page-main--body-container--item--notification {
  background-color: rgb(174, 214, 214);
  border: 2px solid red;
  width: 50%;
  padding: 5px;
}

/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  .grid-responsive-width.page-main .page-main--header-title p {
    margin-left: 5px;
    font-size: 21px;
  }

  .grid-responsive-width.page-main .page-main--header-title .header-btn button {
    width: fit-content;
    margin: 5px 5px 5px 0;
    height: 40px;
  }

  .grid-responsive-width.page-main .page-main--body-container {
    margin: 5px;
    gap: 20px;
  }

  .page-main--body-container--item h5 {
    font-size: 18px !important;
  }
  .page-main--body-container--item--notification p {
    font-size: 18px !important;
  }

  .grid-responsive-width.page-main
    .page-main--body-container
    .page-main--body-container--item--notification {
    width: 100%;
  }
}
