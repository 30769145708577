.member-list {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.member-list .member-item {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 10px;
}

.member-list .member-item .member-name {
  font-size: 10px;
}
.member-list .member-item .member-remove {
  margin-left: auto;
}

.member-list .member-item .icon {
  font-size: 10px;
}

.member-list .member-item .online-icon .icon {
  color: green;
}
.member-list .member-item .onhold-icon .icon {
  color: orange;
}
.member-list .member-item .busy-icon .icon {
  color: red;
}

@media (min-width: 1300px) and (min-height: 800px) {
  .member-list .member-item .icon {
    font-size: 14px;
  }
  .member-list .member-item .member-name {
    font-size: 14px;
  }
}
