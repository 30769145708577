.controls-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  background-color: unset !important;
  box-shadow: unset !important;
  margin-bottom: 20px;
}
.controls-menu .local-actions {
  display: flex;
  flex-direction: row;
}
.controls-menu .remote-actions {
  display: flex;
  flex-direction: row;
}

.controls-menu .ch-control-bar-item-label {
  display: flex !important;
  text-align: -webkit-center;
  padding-top: 0rem !important;
  text-align: center;
  font-size: 8px !important;
}

.controls-menu--video {
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  margin: auto 0;
  background-color: unset !important;
  box-shadow: unset !important;
}

.controls-menu--video .ch-icon {
  color: white !important;
}
.controls-menu--video .ch-control-bar-item-label {
  color: white !important;
}

.remote-actions i.icon {
  font-size: 1.4em !important;
}

.popup--warning--ar-function {
  position: fixed;
  left: 5px;
  bottom: 69px;
  width: auto;
  height: 18px;
  z-index: 999;
}

@media (max-width: 600px) {
  .controls-menu {
    position: inherit !important;
  }
}

@media (max-width: 610px) and (max-height: 500px) {
  .controls-menu--video {
    bottom: 0;
    position: absolute;
    margin: auto 0;
    background-color: unset !important;
    box-shadow: unset !important;
  }

  .grid-responsive-width {
    height: unset !important;
    width: unset !important;
    max-width: 100% !important;
  }

  div[class*='ch-remote-video'] .ch-nameplate {
    width: 100px !important;
    position: sticky !important;
    bottom: 400px !important;
  }

  div[class*='ch-remote-video'] {
    width: 100% !important;
    height: 87vh !important;
  }

  .iztrcv {
    width: 100% !important;
    height: 87vh !important;
  }
}

@media (min-width: 1200px) {
  .controls-menu .ch-control-bar-item-label {
    font-size: 14px !important;
    text-align: center;
    padding-top: 1.5rem !important;
  }

  .controls-menu .ch-control-bar-item-iconButton .ch-icon {
    width: 2rem !important;
    height: 2rem !important;
  }

  .controls-menu .remote-actions {
    display: flex;
    flex-direction: row;
    gap: 20px
  }

  .remote-actions i.icon {
    font-size: 2em !important;
  }
}
