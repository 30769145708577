.sc_widget{
  align-self: self-end;
  position: fixed;
  bottom: 2.5rem;
}

.sc_launcher {
  width: 60px;
  height: 60px;
  background-color: var(--boschBlue50);
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 50%;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
  font-family: Bosch Sans,'Helvetica Neue',Arial,Helvetica,sans-serif;
  z-index: 997;
  cursor: pointer;
}

.sc_launcher:before {
  content: '';
  position: relative;
  display: block;
  width: 60px;
  height: 60px;  
  border-radius: 50%;
  transition: box-shadow 0.2s ease-in-out;
}

.sc_launcher.opened {
  visibility: hidden;
}

.sc_launcher .sc_closed_icon {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 5px;
  bottom: 0;
  right: 7px;
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
}


.sc_launcher.opened .sc_closed_icon {
  transform: rotate(-90deg);
  opacity: 0;
}

.sc_launcher.opened:before {
  box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
}

.sc_launcher:hover {
  box-shadow: 0 0px 27px 1.5px rgba(0,0,0,0.2);
}
