.message-input-form {
  flex: auto;
}

span.text-input {
  display: flex;
  flex-grow: 1;
}

.text-input input {
  width: 100%;
}

/*  */
.input-container,
.footer-input-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px;
}

.input-container {
  width: 100% !important;
  background-color: #e0e2e5 !important;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-container .ui.input,
.footer-input-container .ui.input {
  width: 100%;
}

.input-container .ui.input input,
.footer-input-container .ui.input input {
  padding-right: 0.875em;
  border: 0 !important;
  background-color: #e0e2e5 !important;
  border-radius: 0 !important;
  padding-left: 0px;
}

.footer-input-container .ui.input input {
  border-bottom: 1px solid black !important;
}

.input-container .input-button,
.footer-input-container .input-button {
  padding: 0.6875em 0.8em;
  margin: 0;
  width: 40px;
}

.footer-input-container .input-button {
  border-bottom: 1px solid black;
}

.footer-input-container .input-button:hover {
  background-color: #e0e2e5;
}

.footer-input-container .input-button:focus,
.input-container .input-button:focus {
  background-color: #e0e2e5;
}

/* .input-container .ui.input input:focus {
  background-color: #d1e4ff !important;
  border-bottom: 2px solid #008ecf !important;
} */

.input-container .ui.input:focus-within ~ .ui.button.input-button {
  border-bottom: 2px solid #008ecf !important;
}

.search-result-background {
  background-color: #ffffff;
  box-shadow: 0 -16px 0 #fff, 0 0 16px rgb(0 0 0 / 25%);
  margin-top: 20px;
}

.search-result-background .search-result-container {
  /* position: absolute;
  top: 127%; */
  margin: 0 auto;
  padding: 10px 0;
  max-width: 1120px;
  max-height: 300px;
  overflow: auto;
}

.search-result-background
  .search-result-container
  .search-suggestion-container
  .search-suggestion {
  padding: 12px 20px;
}

.search-result-background
  .search-result-container
  .search-suggestion-container
  .search-suggestion:hover {
  color: #005691;
  cursor: pointer;
}

.search-result-background .search-result-container .all-results-suggestion {
  padding: 12px 20px 20px 20px;
  color: #007bc0;
}

.search-result-background
  .search-result-container
  .all-results-suggestion:hover {
  color: #005691;
  cursor: pointer;
}

.input-container .ui.input.send-input > input:focus {
  background-color: #e0e2e5 !important;
  border-bottom: none !important;
}

.input-container .ui.input:focus-within ~ .ui.button.input-button {
  border-bottom: 2px solid #e0e2e5 !important;
}

.ui.input.send-input > input {
  padding-left: 1rem;
}

.input-upload {
  padding: 0.6875em 0.8em;
  margin: 0;
  width: 40px;
  cursor: pointer;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6);
}

.input-upload > label {
  cursor: pointer;
}

.input-upload > input {
  display: none;
}

.image-preview-container {
  padding: 10px !important;
  display: flex;
  /* max-height: 160px; */
  flex-direction: column;
  margin: 0px !important;
}

.image-preview-container-image {
  /* padding: 10px !important; */
  display: flex;
  max-height: 160px;
  margin: 0px !important;
}

.details {
  margin-left: 5px;
  margin-right: 15px;
}

.button-remove-attachment {
  cursor: pointer;
}

.icon-file-upload {
  font-size: 2em;
}

.progress-bar {
  margin: 1px !important;
}