.startFeature {
  margin: 0 1rem 0.5rem !important;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.startFeature__button {
  background: var(--boschBlue50) !important;
  margin: 0 !important;
  color: var(--boschWhite) !important;
  font-size: var(--fs-77) !important;
  height: fit-content !important;
}
.startFeature__button.gray {
  background: var(--boschGray40) !important;
  margin: 0 !important;
  color: var(--boschWhite) !important;
  font-size: var(--fs-77) !important;
  height: fit-content !important;
}

.startFeature__button:hover {
  background: var(--boschBlue40) !important;
}
.startFeature__button.gray:hover {
  background: var(--boschGray20) !important;
}
