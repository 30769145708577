#loadingPanel {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  background-color: rgb(253, 253, 253, 0.75) !important;
}

#loadingPanel.show {
  display: flex;
}

.a-activity-indicator {
  height: 72px;
  width: 72px;
  position: relative;
}

.a-activity-indicator__top-box {
  animation-name: top-box-animation-default;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  background-color: var(--boschRed50);
  position: absolute;
  height: calc(100% / 3);
  width: calc(100% / 3);
  top: calc(100% / 3);
  left: calc(100% / 3);
  z-index: 1;
}

.a-activity-indicator__bottom-box {
  animation-name: bottom-box-animation-default;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  background-color: var(--boschPurple50);
  position: absolute;
  height: calc(100% / 3);
  width: calc(100% / 3);
  top: calc(100% / 3);
  left: calc(100% / 3);
  z-index: 0;
}

@keyframes top-box-animation-default {
  0%,
  100% {
    transform: translate(0, 0);
  }
  12.5% {
    transform: translate(-24px, 0);
  }
  24.99% {
    background-color: var(--boschRed50);
    transform: translate(0, 0);
  }
  25% {
    background-color: var(--boschBlue50);
  }
  37.5% {
    transform: translate(0, -24px);
  }
  50% {
    transform: translate(0, 0);
  }
  62.5% {
    transform: translate(-24px, 0);
  }
  74.99% {
    background-color: var(--boschBlue50);
    transform: translate(0, 0);
  }
  75% {
    background-color: var(--boschRed50);
  }
  87.5% {
    transform: translate(0, -24px);
  }
}

@keyframes bottom-box-animation-small {
  0%,
  100% {
    transform: translate(0, 0);
    background-color: var(--boschPurple50);
  }
  12.5% {
    transform: translate(16px, 0);
    z-index: 2;
  }
  25% {
    transform: translate(0, 0);
    z-index: 2;
  }
  37.5% {
    transform: translate(0, 16px);
    z-index: 0;
  }
  49.99% {
    transform: translate(0, 0);
    z-index: 0;
    background-color: var(--boschPurple50);
  }
  50% {
    background-color: var(--boschGreen50);
  }
  62.5% {
    transform: translate(16px, 0);
    z-index: 2;
  }
  75% {
    transform: translate(0, 0);
    z-index: 2;
  }
  87.5% {
    transform: translate(0, 16px);
    z-index: 0;
  }
  99.99% {
    background-color: var(--boschGreen50);
  }
}
@keyframes bottom-box-animation-large {
  0%,
  100% {
    transform: translate(0, 0);
    background-color: var(--boschPurple50);
  }
  12.5% {
    transform: translate(42.66px, 0);
    z-index: 2;
  }
  25% {
    transform: translate(0, 0);
    z-index: 2;
  }
  37.5% {
    transform: translate(0, 42.66px);
    z-index: 0;
  }
  49.99% {
    transform: translate(0, 0);
    z-index: 0;
    background-color: var(--boschPurple50);
  }
  50% {
    background-color: var(--boschGreen50);
  }
  62.5% {
    transform: translate(42.66px, 0);
    z-index: 2;
  }
  75% {
    transform: translate(0, 0);
    z-index: 2;
  }
  87.5% {
    transform: translate(0, 42.66px);
    z-index: 0;
  }
  99.99% {
    background-color: var(--boschGreen50);
  }
}
@keyframes top-box-animation-large {
  0%,
  100% {
    transform: translate(0, 0);
  }
  12.5% {
    transform: translate(-42.66px, 0);
  }
  24.99% {
    background-color: var(--boschRed50);
    transform: translate(0, 0);
  }
  25% {
    background-color: var(--boschBlue50);
  }
  37.5% {
    transform: translate(0, -42.66px);
  }
  50% {
    transform: translate(0, 0);
  }
  62.5% {
    transform: translate(-42.66px, 0);
  }
  74.99% {
    background-color: var(--boschBlue50);
    transform: translate(0, 0);
  }
  75% {
    background-color: var(--boschRed50);
  }
  87.5% {
    transform: translate(0, -42.66px);
  }
}
@keyframes top-box-animation-small {
  0%,
  100% {
    transform: translate(0, 0);
  }
  12.5% {
    transform: translate(-16px, 0);
  }
  24.99% {
    background-color: var(--boschRed50);
    transform: translate(0, 0);
  }
  25% {
    background-color: var(--boschBlue50);
  }
  37.5% {
    transform: translate(0, -16px);
  }
  50% {
    transform: translate(0, 0);
  }
  62.5% {
    transform: translate(-16px, 0);
  }
  74.99% {
    background-color: var(--boschBlue50);
    transform: translate(0, 0);
  }
  75% {
    background-color: var(--boschRed50);
  }
  87.5% {
    transform: translate(0, -16px);
  }
}

@keyframes bottom-box-animation-default {
  0%,
  100% {
    transform: translate(0, 0);
    background-color: var(--boschPurple50);
  }
  12.5% {
    transform: translate(24px, 0);
    z-index: 2;
  }
  25% {
    transform: translate(0, 0);
    z-index: 2;
  }
  37.5% {
    transform: translate(0, 24px);
    z-index: 0;
  }
  49.99% {
    transform: translate(0, 0);
    z-index: 0;
    background-color: var(--boschPurple50);
  }
  50% {
    background-color: var(--boschGreen50);
  }
  62.5% {
    transform: translate(24px, 0);
    z-index: 2;
  }
  75% {
    transform: translate(0, 0);
    z-index: 2;
  }
  87.5% {
    transform: translate(0, 24px);
    z-index: 0;
  }
  99.99% {
    background-color: var(--boschGreen50);
  }
}
.a-activity-indicator-small .a-activity-indicator__top-box {
  animation-name: top-box-animation-small;
}

.a-activity-indicator-small .a-activity-indicator__bottom-box {
  animation-name: bottom-box-animation-small;
}

.a-activity-indicator-large .a-activity-indicator__bottom-box {
  animation-name: bottom-box-animation-large;
}

.a-activity-indicator-large .a-activity-indicator__top-box {
  animation-name: top-box-animation-large;
}

.a-activity-indicator-large {
  height: 128px;
  width: 128px;
}

.a-activity-indicator-small {
  height: 48px;
  width: 48px;
}
