.header-private-pcs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.grid-responsive-width.page-header-top-private {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.supergraphic {
  display: flex;
  width: 100%;
  height: 0.375rem;
  z-index: 100;
  position: relative;
}

/* header box container */

.header-large-box--logo {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.header-large-box--logo .header-large-box--logo--vertical-line {
  border-left: 1px solid black;
  margin: 25px;
  height: 40px;
}
.header-large-box--logo .header-large-box--logo--title {
  display: flex;
  align-items: center;
}

.header-large-box--logo .header-large-box--logo--title p {
  font-size: 14px;
}

/* Styles for mobile devices */
@media only screen and (max-width: 500px) {
  .grid-responsive-width {
    max-width: 100% !important;
  }

  .logo-image {
    width: 30% !important;
  }

  .header-large-box--logo .header-large-box--logo--vertical-line {
    margin: 15px 10px;
    height: auto;
  }

  .header-large-box--logo .header-large-box--logo--title {
    font-size: 8px !important;
  }
}
