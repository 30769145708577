.publicDashboard {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: calc(100% - 56px);
}

.publicDashboard .header-title {
  display: flex;
  justify-content: end;
  height: 5%;
  align-items: center;
}
.publicDashboard .header-title.onTranslate {
  display: flex;
  justify-content: space-between;
  height: 5%;
  align-items: center;
}
.publicDashboard .isTranslate--note {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.publicDashboard .header-title p {
  font-size: 15px;
  font-weight: bold;
  margin-left: 0px;
}
.publicDashboard .header-title a {
  color: green;
}
.publicDashboard .session-title--active {
  display: flex;
  justify-content: flex-start;
  height: 8%;
  align-items: center;
  background-color: green;
  color: white;
}
.publicDashboard .session-title {
  display: flex;
  justify-content: flex-start;
  height: 8%;
  align-items: center;
  background-color: orangered;
  color: white;
}

.publicDashboard p {
  font-size: 22px;
  font-weight: bold;
  margin-left: 15px;
}

.publicDashboard .chatting-area {
  height: 85%;
}

.joined--note {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding-top: 10px;
  border-left: 0.01px solid #e0e2e5;
  padding-right: 0.2em;
  border-right: 0.01px solid #e0e2e5;
}

.joined--note .date--badge {
  padding: 0.1rem 0.4rem 0.125rem;
  border-radius: 0.5rem;
  line-height: 1.43;
  color: #ffffff;
  font-size: 0.65rem;
  background-color: #616672;
  width: -moz-fit-content;
}
.joined--note .join--title p {
  line-height: 1.43;
  font-size: 0.65rem;
}

/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  .publicDashboard {
    width: calc(100% - 2rem);
  }
}

/* Styles for tablet devices */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .publicDashboard {
    width: calc(100% - 2rem);
  }
}
