.loading {
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem 5px 1rem !important;
}

.dot_container {
  display: flex;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: var(--boschGray20); /* Change the color as needed */
  border-radius: 50%;
  margin: 0 5px;
  animation: bounce 1s infinite ease-in-out;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}
