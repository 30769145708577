.ui.test-dimmer {
  background: rgba(0, 0, 0, 0.85) !important;
}

.options {
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
  justify-content: center;
  align-items: center;
}

.options--item {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: white !important;
  border: 1px solid #0b76c2 !important;
  color: #0b76c2 !important;
  height: 65px !important;
  width: 226px !important;
}

.options--item:hover {
  background-color: #0b76c2 !important;
  border: 1px solid white !important;
  color: white !important;
}

.options--item:hover .visualConnect-image {
  background-image: url('../../../assets/AR-white.png');
  height: 42px;
  background-repeat: no-repeat;
  background-size: cover;
}

.options--item .icon {
  font-size: 35px;
}

.header-color-line {
  background-color: #00b050;
  height: 8px;
  width: auto;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.visualConnect-image {
  background-image: url('../../../assets/Ar-blue.png');
  height: 42px;
  background-repeat: no-repeat;
  background-size: cover;
}

.random--code {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.random--code .random--code-title {
  font-size: 20px;
  font-weight: 600;
}
.random--code .random--code-content {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.random--code .random--code-content a {
  font-weight: 600;
  color: black;
}

.random--code .random--code-connect {
  width: 40%;
  align-self: end;
}

.close.icon {
  top: -2.5rem !important;
  right: -0.5rem !important;
  color: white !important;
}

/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  .start-new-header {
    font-size: 14px !important;
  }
}
/* Styles for mobile devices */
@media only screen and (max-width: 767.98px) {
  .ui.modal > .close {
    color: white !important;
    top: -2.5rem !important;
    right: -0.5rem !important;
  }
}
@media only screen and (min-width: 767px) {
  .create-new-modal {
    width: fit-content !important;
    margin: 0;
  }
}
