.sc_header {
  background: white;
  min-height: 30px;
  color: black;
  border-bottom: 0.0625rem solid #bbb;
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.menu_icon_area {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu_icon_area p {
  font-size: 1.5rem !important;
}

.header_icon_button {
  border: none !important;
  background-color: white !important;
  margin: 0 !important;
}

.header_icon_button i {
  font-size: 1.5rem !important;
}

.dropdown_menu_icon {
  position: relative;
}

.dropDown_box {
  position: absolute;
  background-color: var(--boschWhite);
  z-index: 999;
  width: max-content;
  height: max-content;
  top: 46px;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.dropDown_box .translate_modal_btn {
  background-color: var(--boschWhite);
  cursor: pointer;
  margin: 0 !important;
  font-size: 0.85rem;
  font-weight: 500;
}

.dropDown_box .translate_modal_btn.disable i{
  color: green;
}

@media (max-width: 450px) {
  .sc_header {
    border-radius: 0px;
  }
}
