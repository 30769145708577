.errorModal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.errorModal .colorBar {
  width: 100%;
  height: 20px;
  background-color: orange;
}

.error-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 25px 0px 25px;
}

.error-area .btn--option {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 5px;
}
.error-area .btn--option .button{
  border-radius: 0% !important;
  font-weight: 300 !important;
}

.errorModal .error--title {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
}

.errorModal .error--title p{
  font-size: 13px;
  font-weight: 600;
}
.errorModal .error--content p{
  font-size: 13px;
  font-weight: 500;
}

.errorModal .error--title .Boschicon {
  font-size: 30px;
  font-weight: bold;
}
