.videos {
  position: unset !important;
  height: 77vh !important;
  width: unset !important;
}

.videos .ch-video {
  object-fit: contain !important;
}

video {
  position: unset !important;
}

div[class*='ch-remote-video'] {
  position: unset !important;
  overflow-y: hidden;
  text-align: center;
}

div[class*='ch-remote-video'] .ch-nameplate {
  width: 100px !important;
  position: sticky !important;
}

.popup--notification--attendee {
  position: fixed;
  right: 5px;
  bottom: 69px;
  width: auto;
  height: 18px;
  z-index: 999;
}

.meetingSession {
  display: flex;
  flex-direction: column;
}

.meetingSession .meetingControls {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1300px) {
  .videos {
    position: unset !important;
    max-height: 77vh !important;
    width: unset !important;
  }
}

@media (max-width: 625px) {
  .videos {
    position: unset !important;
    max-height: 70vh !important;
    width: unset !important;
  }
}
