body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  overflow-y: unset;
  white-space: pre-line;
}

:root {
  --fs-10: 4rem; /*64px: For header of home page*/
  --fs-20: 2.5rem; /*40px*/
  --fs-30: 2.25rem; /*36px For section header*/
  --fs-40: 2rem; /*32px*/
  --fs-50: 1.75rem; /*28px*/
  --fs-60: 1.5rem; /*24px*/
  --fs-70: 1.25rem; /*20px*/
  --fs-75: 1.125rem; /*18px Modal header*/
  --fs-77: 0.875rem; /*14px Modal header*/
  --fs-80: 0.813rem; /*13px small text*/
  --fs-normal: 1rem; /*16px small text*/
  --fs-100: 0.625rem; /*10px small text*/
}

:root {
  --text-s: 0.75rem;
  --text-m: 1rem;
  --text-l: 1.25rem;
  --text-xl: 1.5rem;
  --text-2xl: 2rem;
  --text-3xl: 2.5rem;
  --text-4xl: 3rem;
  --text-5xl: 4rem;
  --text-6xl: 5rem;
}

:root {
  --boschWhite: #ffffff;
  --boschBlack: #000000;
  --boschBlack20: rgba(34, 36, 38, 0.15);
  --boschBlue90: #d1e4ff;
  --boschBlue85: #b8d6ff;
  --boschBlue80: #9dc9ff;
  --boschBlue70: #56b0ff;
  --boschBlue50: #007bc0;
  --boschBlue45: #006ead;
  --boschBlue40: #00629a;
  --boschBlue30: #004975;
  --boschGray100: #d9d9d9;
  --boschGray95: #eff1f2;
  --boschGray90: #e0e2e5;
  --boschGray80: #c1c7cc;
  --boschGray70: #a4abb3;
  --boschGray75: #b2b9c0;
  --boschGray65: #979ea4;
  --boschGray60: #8a9097;
  --boschGray50: #71767c;
  --boschGray40: #595e62;
  --boschGray35: #4e5256;
  --boschGray30: #43464a;
  --boschGray20: #2e3033;
  --boschGray20-gradient10: rgb(46, 48, 51, 0.1);
  --boschRed90: #ffd9d9;
  --boschRed85: #ffc6c6;
  --boschRed50: #ed0007;
  --boschRed45: #d50005;
  --boschRed40: #be0004;
  --boschGreen90: #b8efc9;
  --boschGreen85: #9be4b3;
  --boschGreen80: #86d7a2;
  --boschGreen70: #5ebd82;
  --boschGreen60: #37a264;
  --boschGreen50: #00884a;
  --boschGreen45: #007a42;
  --boschGreen40: #006c3a;
  --boschGreen30: #00512a;
  --boschYellow90: #ffdf95;
  --boschYellow85: #ffcf00;
  --boschSilver: #eff1f2;
  --boschPurple50: #c535bc;
  --boschPurple40: #9e2896;
  --boschTurquoise50: #18837e;
  --boschTurquoise45: #147671;
  --floating-background-color: #00000040;
}

:root {
  --rd-button-min-width: 9.375rem; /* 9.375rem = 150px */
  --rd-button-small-min-width: 7.8125rem; /* 7.8125rem = 125px */
  --rd-button-padding: 0.75rem 1rem;
  --rd-button-small-padding: 0.5rem 0.75rem;
  --rd-button-bgr-color: var(--boschBlue50);
  --dashboard-item-base-width: 170px;
  --dashboard-item-base-height: 145px;
}

:root {
  --card-spacing: 1.5rem;
  --card-spacing-sm: 1rem;
  --card-spacing-xs: 0.75rem;
  --card-spacing-lg: 2rem;
  --card-spacing-xl: 2.5rem;
  --card-spacing-horizontal: 1.5rem 1rem;
  --card-spacing-vertical: 1rem 1.5rem;
  --card-spacing-sm-horizontal: 1rem 0.5rem;
  --card-spacing-sm-vertical: 0.5rem 1rem;
  --card-border-radius: 0;
  --card-responsive-spacing: 0.5rem;
}
