.sc_chat_window {
  width: 370px;
  height: calc(100% - 120px);
  max-height: 30rem;
  position: fixed;
  right: 25px;
  bottom: 1rem;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 10px rgba(148, 149, 150, 0.3);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  z-index: 998;
}

.sc_chat_window.closed {
  opacity: 0;
  visibility: hidden;
  bottom: 30px;
}

.btns_confirm_close_session {
  margin: 0 1rem 0.5rem !important;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.btn_confirm_close_session__button {
  background: var(--boschBlue50) !important;
  margin: 0 !important;
  color: var(--boschWhite) !important;
  font-size: var(--fs-77) !important;
  height: fit-content !important;
}
.btn_confirm_close_session__button.gray {
  background: var(--boschGray40) !important;
  margin: 0 !important;
  color: var(--boschWhite) !important;
  font-size: var(--fs-77) !important;
  height: fit-content !important;
}

.btn_confirm_close_session__button:hover {
  background: var(--boschBlue40) !important;
}
.btn_confirm_close_session__button.gray:hover {
  background: var(--boschGray20) !important;
}

.content_confirm_close_session{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.content_confirm_close_session i{
  font-size: 2rem !important;
}

@media (max-width: 450px) {
  .sc_chat_window {
    width: 100%;
    height: 100%;
    max-height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
  }
  .sc_chat_window {
    transition: 0.1s ease-in-out;
  }
  .sc_chat_window.closed {
    bottom: 0px;
  }
}
