.user-block {
  /* Custom style */
  right: 0;
  display: inline-block;
  position: absolute;
  padding-right: 1rem;
}

.user-block a {
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: white;
  padding-left: 24px;
}

.user-info {
  position: relative;
  display: inline-block;
  text-decoration-line: none;
}

/* Tooltip text */
.user-info .tooltiptext {
  visibility: hidden;
  background-color: black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  margin-left: -100px;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.user-info:hover .tooltiptext {
  visibility: visible;
}

.channel-content-container-grid {
  display: grid;
}

.toggles {
  backdrop-filter: none;
}

.channel-content-container {
  grid-template-columns: 3fr 1fr;
  height: 100%;
  backdrop-filter: none;
}

.channel-container {
  background: #eff1f2;
  padding: unset !important;
  overflow-y: auto;
  height: 100%;
}

.messaging-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.messaging-container p {
  margin-top: 115px;
  text-align: center;
}

ul {
  list-style: none;
}

.content-message-container {
  padding: 0 !important;
  height: 100%;
}

.channel-list {
  height: 100%;
  width: 100% !important;
  padding: unset;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.channel-list .channel-list-btn {
  background-color: white;
  border: 1px solid rgb(198, 198, 198);
  min-height: fit-content !important;
  margin: 0.2rem 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn--content--title {
  flex: 1;
  height: 100%;
  text-align: start;
}

.btn--content--title .content--title {
  padding: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn--content--title .content--title p {
  font-size: 0.85rem;
}

.btn--content--icon-delete {
  height: 100%;
  align-content: center;
}

.btn--content--icon-delete:hover i.icon {
  color: red;
}

.channel-list .channel-list-btn:hover {
  background-color: #c3c3c3;
}

.channel-list .channel-list-btn:hover,
.channel-list .channel-list-btn:hover p {
  cursor: pointer;
  color: black;
}

.channel-list .channel-list-btn p {
  font-size: 0.75rem;
  color: rgb(113, 118, 124);
}

.channel-list .channel-list-btn.active {
  background-color: #0070c0;
}

.channel-list .channel-list-btn.active p {
  color: white;
}

li.sc-jfmDQi {
  border-bottom: 1px solid #ddd;
}

.sc-gsnTZi.cinvVZ.ch-channel-button {
  background: #eff1f2;
}

.sc-gsnTZi.cinvVZ.ch-channel-button:hover {
  background: #fff;
}

.sc-jfmDQi.kVLBPO.ch-selected .sc-gsnTZi.cinvVZ.ch-channel-button:hover {
  background: #eff1f2;
}

.kVLBPO.ch-selected .ch-channel-button:hover {
  color: black;
}

.QthiC.ch-selected .ch-channel-button:hover {
  color: black;
}

.ch-popover-menu {
  background: #d3d3d3;
  border: 1px solid rgba(0, 0, 0, 0.87) !important;
}

.message-list-container.loading-message {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.ch-unread .ch-channel-button::before {
  content: url('../../assets/svg/notification.svg');
  width: 1rem;
  height: 1rem;
}

.ch-channel-button {
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  padding: 11px 1rem !important;
  width: 100% !important;
  border: 1px solid rgb(181, 180, 180) !important;
}

.ch-label {
  width: calc(100% - 1rem) !important;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}

.btn-translate-active {
  background-color: #327aff !important;
  color: white !important;
}

.translate-modal .dropdown-language-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*Messages*/
.messages {
  margin: 10px 5px 5px 5px;
}

.messages .messages-grid .messages-left-side {
  height: 75vh;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.messages .messages-grid .messages-middle-side {
  height: 75vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.messages .messages-grid .messages-right-side {
  height: 75vh;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.messages .messages-grid .messages-right-side .title {
  font-size: 14px;
  font-weight: bold;
  margin: 2px;
}

.messages-left-side .bosch-expert-info {
  width: 100%;
  height: 28vh;
  background-color: #ffffff;
}

.messages-left-side .list-channel {
  width: 100%;
  height: 55vh;
  background-color: #ffffff;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.messages-left-side .list-channel .list-channel-title {
  text-align: center;
  margin: 5px 0px 5px 0px;
  font-weight: bold;
}

.messages-middle-side .messages--function {
  width: 100%;
  height: 15%;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.messages-middle-side .messages--function .main--function {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
  margin-left: 95px;
  margin-top: 5px;
}

.messages-middle-side .messages--function .end--function {
  margin-right: 20px;
  margin-top: 5px;
}

.messages-middle-side .messages--function .main--function--icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  font-size: 10px;
  color: #0b76c2;
}

.messages-middle-side .messages--function .main--function--icon .icon {
  font-size: 25px;
  padding: 0px !important;
}

.messages-middle-side .messages--function .main--function .function--btn {
  background-color: #0070c0 !important;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-left: 4px;
}

.messages-middle-side
  .messages--function
  .main--function
  .function--btn--active {
  background-color: #00b050 !important;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-left: 4px;
}

.messages-middle-side .messages--function .main--function .function--title {
  color: #0070c0;
}

.messages-middle-side
  .messages--function
  .main--function
  .function--title--active {
  color: #00b050;
}

.messages-middle-side .messages--function .end--function .closeChannel--btn {
  background-color: red !important;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-left: 5px;
}

.messages-middle-side .messages--chatting-area {
  width: 100%;
  height: 84%;
  background-color: #ffffff;
}

.messages-right-side .contact-detail {
  width: 100%;
  height: 30%;
  background-color: #ffffff;
  padding: 5px;
  overflow: auto;
}

.messages-right-side .request-detail {
  width: 100%;
  height: 40%;
  background-color: #ffffff;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
}

.messages-right-side .attendees {
  width: 100%;
  height: 30%;
  background-color: #ffffff;
  padding: 5px;
  overflow: auto;
}

.messages-left-side .bosch-expert-info .logo-setting {
  display: flex;
  justify-content: end;
  height: 25%;
}

.messages-left-side .bosch-expert-info .bosch-expert-avatar {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.5rem;
  height: 70%;
  justify-content: center;
}

.messages-left-side .bosch-expert-info .logo-setting .setting--btn {
  background-color: white;
}

.contact-detail .content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 10px;
  word-break: break-all;
  font-size: 10px;
}

.contact-detail .content .address p {
  margin: 0;
}

.contact-detail .content .contact p {
  margin: 0;
}

.contact-detail .content .wsa_check .wsa_active {
  color: var(--boschGreen50);
  font-weight: 600;
}

.contact-detail .content .wsa_check .wsa_no_active {
  color: var(--boschRed50);
  font-weight: 600;
}

.request-detail .content .detail {
  font-size: 9px;
  margin-left: 10px;
}

.request-detail .content .detail p {
  margin: 0;
}

.request-detail .request-detail-grid {
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.detail--value p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.content .member-item {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 10px;
}

.content .member-item .icon {
  font-size: 10px;
}
.content .member-item .member-name {
  font-size: 10px;
}
.content .member-item .member-remove {
  margin-left: auto;
}

.content .bosch-expert-email {
  font-size: 14px;
}

.messages-left-side .bosch-expert-info .logo-setting .setting--btn i.icon {
  font-size: 15px;
}
.messages-left-side
  .bosch-expert-info
  .bosch-expert-avatar
  .setting--avatar
  .setting--avatar--img {
  height: 60px;
  border-radius: 100%;
}

.messages-left-side
  .bosch-expert-info
  .bosch-expert-avatar
  .setting--avatar
  i.icon {
  font-size: 60px;
  color: green;
}

.messages-left-side .bosch-expert-info .bosch-expert-avatar p {
  font-size: 12px;
}

/* Mobile layout */

.messages-mobile-layout {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0.5rem;
}

.messages-mobile-layout .bosch-expert-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.messages-mobile-layout .bosch-expert-info .bosch-expert-name span {
  font-weight: 600;
}
.messages-mobile-layout
  .bosch-expert-info
  .bosch-expert-avatar
  .setting--avatar
  .setting--avatar--img {
  height: 60px;
  border-radius: 100%;
}

.messages-mobile-layout
  .bosch-expert-info
  .bosch-expert-avatar
  .setting--avatar
  i.icon {
  font-size: 60px;
  color: green;
}

.list-channel-mobile-layout {
  background-color: white;
  height: auto;
  width: 100%;
  height: calc(72vh - 1rem);
  margin-top: 1rem;
  padding: 0.5rem;
}
.list-channel-mobile-layout .list-channel-title {
  text-align: center;
  font-weight: 600;
}
.list-channel-mobile-layout .channel-list {
  margin-top: 1rem;
}

.list-channels-mobile {
  display: inline-block !important;
  height: 72vh;
}

.chatting-mobile-layout {
  background-color: white;
  width: 100%;
  height: calc(72vh - 1rem);
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-mobile-layout {
  background-color: white;
  width: 100%;
  height: calc(72vh - 1rem);
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 8px 8px 0px 8px;
  gap: 2rem;
}

.bosch-expert-info .channel-name {
  display: flex;
  flex-direction: column;
}
.bosch-expert-info .channel-name p {
  margin: 0;
  padding: 0;
  font-size: 11px;
  text-align: center;
}
.bosch-expert-info .channel-name .channel-name-title {
  font-weight: 600;
}
.bosch-expert-info .channel-name .online-title {
  color: #00b050;
}

.menu-mobile-layout .menu-mobile-layout--close i.Boschicon,
.bosch-expert-info .back-to-list i.Boschicon,
.bosch-expert-info .menu-channel i.Boschicon {
  font-size: 22px;
  cursor: pointer;
}

.menu-mobile-layout .menu-mobile-layout--close {
  text-align: end;
}

.menu-mobile-layout-option {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.menu-mobile-layout-option .functionality-title {
  font-weight: 600;
}
.menu-mobile-layout-option .functionality-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.menu-mobile-layout-option .function--btn {
  background-color: white !important;
  height: fit-content;
  width: 100%;
  padding: 8px;
  border: 0.5px solid #d3d3d3;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: black;
  font-weight: 500;
}

.function--btn.detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.function--btn.close {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: red;
  width: 100%;
}

.menu-mobile-layout-option i.Boschicon {
  font-size: 25px;
  cursor: pointer;
}

.menu-mobile-layout-option .function--btn--active {
  background-color: #00b050 !important;
  height: fit-content;
  width: 100%;
  padding: 8px;
  border: 0.5px solid #d3d3d3;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-weight: 500;
}

.request-detail-grid.ui.grid {
  margin: 0px !important;
  margin-left: -1rem !important;
}

.visualConnect-image-btn {
  background-image: url('../../assets/AR-Black.png');
  height: 21px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 21px;
}

@media (min-width: 1300px) and (min-height: 800px) {
  .messages-left-side .bosch-expert-info .logo-setting .setting--btn i.icon {
    font-size: 30px;
  }

  .messages-left-side
    .bosch-expert-info
    .bosch-expert-avatar
    .setting--avatar
    .setting--avatar--img {
    height: 60px;
    border-radius: 100%;
  }

  .messages-left-side .bosch-expert-info .bosch-expert-avatar p {
    font-size: 20px;
  }

  .messages-left-side .list-channel .list-channel-title {
    font-size: 25px;
    margin: 20px 0px 20px 0px;
  }

  .message-list-container.loading-message {
    font-size: 24px;
  }

  .messages-middle-side .messages--function .main--function .function--btn {
    background-color: #0070c0 !important;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    margin-left: 4px;
  }

  .messages-middle-side
    .messages--function
    .main--function
    .function--btn--active {
    background-color: #00b050 !important;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    margin-left: 4px;
  }

  .messages-middle-side
    .messages--function
    .main--function
    .function--title--active {
    color: #00b050;
    font-size: 14px;
  }

  .messages-middle-side .messages--function .main--function .function--title {
    font-size: 14px;
  }
  .messages-middle-side .messages--function .end--function .function--title {
    font-size: 14px;
  }

  .messages-middle-side .messages--function .end--function .closeChannel--btn {
    background-color: red !important;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    margin-left: 5px;
  }

  .messaging-container p {
    margin-top: 240px;
    font-size: 25px;
  }

  .messages .messages-grid .messages-right-side .title {
    font-size: 18px;
  }

  .contact-detail .content {
    font-size: 14px;
  }

  .request-detail .content .detail {
    font-size: 14px;
  }
}
