.private-chat-responsive-width {
  width: 100%;
}

.private_anonymous_chat .session-title--active {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: green;
  color: white;
  padding: 0.75rem;
}
.private_anonymous_chat .session-title {
  display: flex;
  justify-content: flex-start;
  padding: 0.75rem;
  align-items: center;
  background-color: orangered;
  color: white;
}
