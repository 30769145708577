.sc-hiMGwR {
  /* margin-left: 0.5rem !important;
    margin-right: auto !important; */
  margin-left: unset !important;
  margin-right: unset !important;
}

.public-message-list-container {
  overflow-y: auto;
  padding-left: 0.2em;
  height: 100%;
  border-left: 0.01px solid #e0e2e5;
  padding-right: 0.2em;
  border-right: 0.01px solid #e0e2e5;
}

.chat-bubble-container-mine {
  background-color: #fff !important;
  margin: 1px !important;
  align-items: end !important;
  flex-direction: column !important;
  margin-right: 0.25rem !important;
}

.chat-bubble-container-them {
  margin: 1rem !important;
  background-color: #fff !important;
  margin: 1px !important;
  display: flex !important;
  align-items: start !important;
  flex-direction: column !important;
}

.chat-bubble-mine {
  background-color: #007bc0 !important;
  margin-right: 0.25rem !important;
  text-align: left !important;
}

.chat-bubble-them {
  background-color: #e0e2e5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.ch-attachment-icon {
  margin: unset !important;
}

.ch-attachment-img {
  width: 200px !important;
}

.ch-attachment-icon svg {
  position: unset !important;
}

.ch-timestamp {
  padding: 0 !important;
  margin-right: 0.25rem !important;
}

.translate {
  font-style: italic;
  font-size: x-small;
}

.translate.link {
  text-decoration: underline;
  cursor: pointer;
}

.message-time{
  margin-right: auto !important;
  color: #50545e;
  font-size: 0.65rem;
  margin-left: 2px;
}
