.chat_wrapper {
  position: relative !important;
  background-color: var(--background) !important;
}

.chat_form {
  height: 100vh !important;
}

.invisible_chat {
  display: none;
}